import React, { Fragment, useRef } from 'react';
import { AreaChart, ReferenceLine, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { wideOutcomePercentiles, mediumOutcomePercentiles, narrowOutcomePercentiles } from '../theming/appConstants';
import { useAppColors } from '../theming/useAppColors';
import { TextNumberFormat } from '../NumberFormatCustom'
import { Alert, IconButton, Paper, Snackbar, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { OpenInFull, Screenshot } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import { Frequency } from '../api_client';


function PercentileFanChart(props: any) {
    const {
        currentAge,
        portfolioDevelopmentTimeSeries,
        referencePortfolioDevelopmentTimeSeries,
        targetAmount,
        horizon,
        height,
        hideHorizon,
        historicalTimeSeries,
        historicalReferenceTimeSeries,
        leftPadding,
        setPercentileChartDialogOpen,
        showLedgend,
        showXAxis,
        startDate,
        showToolbar,
        observationFrequency } = props;

    const theme = useTheme();

    const colors = useAppColors();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const canvasRef = useRef();

    const isFullScreen = setPercentileChartDialogOpen === undefined;
    const fontSize = isFullScreen ? 12 : 10;

    const startYear = startDate ? moment(startDate).toDate().getFullYear() : 0;

    const filled = props.filled === undefined ? true : props.filled;

    const yAxisWidth = leftPadding ? leftPadding : 35;


    const numberOfPercentiles = portfolioDevelopmentTimeSeries.length;
    var bounds: any = [];
    for (let i = 0; i < Math.ceil(numberOfPercentiles / 2); i++) {
        bounds.push({ lower: portfolioDevelopmentTimeSeries[i], upper: portfolioDevelopmentTimeSeries[numberOfPercentiles - (i + 1)] });
    }

    let referenceOutcomeLowerBound: any = undefined;
    let referenceOutcomeUpperBound: any = undefined;
    if (referencePortfolioDevelopmentTimeSeries !== undefined) {
        referenceOutcomeLowerBound = referencePortfolioDevelopmentTimeSeries[0];
        referenceOutcomeUpperBound = referencePortfolioDevelopmentTimeSeries[referencePortfolioDevelopmentTimeSeries.length - 1];
    }

    // TODO need to do ceil?
    // const xAxisLength = (Math.ceil(horizon / 10) * 10) + 1;

    const xAxisLength = observationFrequency === undefined || observationFrequency === Frequency.Annual ? horizon + 1 : horizon * 12 + 1;

    const valueTitle = props.valueTitle ? props.valueTitle : "NAV";
    const referenceTitle = props.referenceTitle ? props.referenceTitle : "Reference";


    // const lastOutcomeIndex = xAxisLength > bounds[0].upper.outcomes.length ? bounds[0].upper.outcomes.length - 1 : xAxisLength - 1;

    // const lastOutComeHighestOutcome = bounds[0].upper.outcomes[lastOutcomeIndex].value;
    // const highestValue = lastOutComeHighestOutcome > targetAmount ? lastOutComeHighestOutcome : targetAmount;


    // const maxAsSingleDigit = highestValue / Math.pow(10, Math.floor(Math.log10(highestValue)));
    // const maxValue = (maxAsSingleDigit < 5 ? Math.ceil(maxAsSingleDigit) : 10) * Math.pow(10, Math.floor(Math.log10(highestValue)));

    if (portfolioDevelopmentTimeSeries[0].outcomes.length === 1) return (<></>);

    const historicalDates = [...new Set([].concat(historicalTimeSeries !== undefined ? historicalTimeSeries.map((s: any) => s.pointInTime) : [])
        .concat(historicalReferenceTimeSeries !== undefined ? historicalReferenceTimeSeries.map((s: any) => s.pointInTime) : []))]
        .map(p => moment(p).valueOf())
        .sort((a, b) => a > b ? 1 : -1);


    var historicalTimeSeriesAsDict = {} as any;
    historicalTimeSeries?.forEach((el: any, index: number) => {
        historicalTimeSeriesAsDict[moment(el.pointInTime).valueOf()] = el.value;
    });

    var historicalReferenceTimeSeriesAsDict = {} as any;
    historicalReferenceTimeSeries?.forEach((el: any, index: number) => {
        historicalReferenceTimeSeriesAsDict[moment(el.pointInTime).valueOf()] = el.value;
    });

    const hasHistoricalReference = historicalReferenceTimeSeries !== undefined;

    const historicalChartData = historicalDates.length > 0 ? historicalDates.map((o: number, i: number): any => {
        const value = historicalTimeSeriesAsDict[o];
        const referenceValue = historicalReferenceTimeSeriesAsDict[o];
        return {
            name: (currentAge + i - historicalDates.length) / 360, // ??
            pointInTime: o,
            narrow: undefined,
            medium: undefined,
            wide: undefined,
            historical: value !== undefined ? [value, value] : undefined,
            historicalReference: referenceValue !== undefined ? [referenceValue, referenceValue] : undefined,
            reference: undefined,
            isHistorical: true
        };
    }) : [];
    const today = moment(historicalTimeSeries?.[historicalTimeSeries.length - 1]?.pointInTime);

    const firstHistoricalPoint = historicalChartData.find(d => d.historical !== undefined || d.historicalReference !== undefined);
    const firstHistorical = firstHistoricalPoint === undefined ? 100 : firstHistoricalPoint.historical !== undefined ? firstHistoricalPoint.historical[0] : firstHistoricalPoint.historicalReference[0];

    const isFundInfo = historicalTimeSeries !== undefined;

    const chartData = historicalChartData.concat(bounds[0].upper.outcomes.slice(0, xAxisLength).map((o: any, i: number) => {
        const dataPoint: any = {
            name: (currentAge + i),
            year: (startYear + (observationFrequency === Frequency.Annual ? i : i / 12)),
            historical: i === 0 && historicalTimeSeries !== undefined && historicalTimeSeries.length > 0 ? [historicalTimeSeries[historicalTimeSeries.length - 1].value, historicalTimeSeries[historicalTimeSeries.length - 1].value] : undefined,
            reference: referencePortfolioDevelopmentTimeSeries !== undefined && referencePortfolioDevelopmentTimeSeries.length > 0 ? [referenceOutcomeLowerBound.outcomes[i]?.value, referenceOutcomeUpperBound.outcomes[i]?.value] : undefined,
            pointInTime: historicalTimeSeries !== undefined ? moment(today).add(i, 'years').valueOf() : undefined,
            isHistorical: false
        };
        bounds.forEach((b: any, bi: any) => {
            dataPoint[bi + ''] = [b.lower.outcomes[i]?.value, b.upper.outcomes[i]?.value];
        })
        return dataPoint;
    }));


    const CustomizedAxisTick = (props: any) => {
        const {
            x, y, payload,
        } = props;

        return (
            <>
                <TextNumberFormat hidePrefix roundToNearest={1} value={payload.value} style={{ fontSize: 10 }}
                    renderText={(value: any) =>
                        <g transform={`translate(${x},${y})`}>
                            <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: 10 }} fill={colors.chart.axisLines} >{value}</text>
                        </g>
                    }
                />

            </>
        );
    }


    const CustomTooltip = ({ active, payload, label }: any) => {
        const toReturn = (current: number) => Math.round((current / firstHistorical - 1) * 10000) / 100;
        if (active && payload && payload.length) {
            const isHistorical = payload?.[0].payload.isHistorical;
            return (
                <div className="custom-tooltip">
                    <Paper style={{ padding: 4, opacity: 0.9 }} square>
                        <Typography variant='body2'>
                            {isHistorical
                                ? <>
                                    Date: {moment(payload?.[0].payload.pointInTime).format('YYYY-MM-DD')}<br />
                                    Return: {payload?.[0].payload.historical ? toReturn(payload?.[0].payload.historical?.[0]) : ''}%<br />
                                    {isFundInfo ? <>{valueTitle}: {payload?.[0].payload.historical?.[0]}<br /></> : <></>}
                                    {hasHistoricalReference ? <>{referenceTitle}:<br /> {valueTitle}: {payload?.[0].payload.historicalReference?.[0]}<br /></> : <></>}
                                </>
                                : <>
                                    {isFundInfo ? <>Date: {moment(payload?.[0].payload.pointInTime).format('YYYY-MM-DD')}<br /></> : <>{timeStepToHorizon(payload, observationFrequency)}<br /></>}
                                    {isFundInfo
                                        ? <>
                                            Forecasted returns:<br />
                                            {bounds.map((b: any, i: any) => {
                                                const result = b.lower.percentile !== b.upper.percentile
                                                    ? <div key={b.lower.percentile}>{toReturn(payload?.[0].payload[i + '']?.[0])}% - {toReturn(payload?.[0].payload[i + '']?.[1]) + '%'} ({Math.round(b.lower.percentile * 100)}% - {Math.round(b.upper.percentile * 100)}%) <br /></div>
                                                    : <div key={b.lower.percentile}>{toReturn(payload?.[0].payload[i + '']?.[0])}% ({Math.round(b.upper.percentile * 100)}%) <br /></div>
                                                return result;
                                            })}
                                            {valueTitle}:<br />
                                        </>
                                        : <></>}
                                    {bounds.map((b: any, i: any) => {
                                        const result = b.lower.percentile !== b.upper.percentile
                                            ? <div key={b.lower.percentile}><TextNumberFormat key={b.lower.percentile} hidePrefix roundToNearest={1} value={payload?.[0].payload[i + '']?.[0]} /> - <TextNumberFormat customInput={Typography} hidePrefix roundToNearest={1} value={payload?.[0].payload[i + '']?.[1]} /> ({Math.round(b.lower.percentile * 100)}% - {Math.round(b.upper.percentile * 100)}%) <br /></div>
                                            : <div key={b.lower.percentile}><TextNumberFormat key={b.lower.percentile} hidePrefix roundToNearest={1} value={payload?.[0].payload[i + '']?.[0]} /> ({Math.round(b.upper.percentile * 100)}%) <br /></div>
                                        return result;
                                    })}
                                    {referencePortfolioDevelopmentTimeSeries !== undefined && referencePortfolioDevelopmentTimeSeries.length > 0 ?
                                        (<>{referenceTitle}:<br />
                                            {bounds[0].lower.percentile !== bounds[0].upper.percentile
                                                ? <><TextNumberFormat key={'reference1'} hidePrefix roundToNearest={1} value={payload?.[0].payload['reference']?.[0]} /> - <TextNumberFormat customInput={Typography} hidePrefix roundToNearest={1} value={payload?.[0].payload['reference']?.[1]} /> ({Math.round(bounds[0].lower.percentile * 100)}% - {Math.round(bounds[0].upper.percentile * 100)}%) <br /></>
                                                : <><TextNumberFormat key={'reference1'} hidePrefix roundToNearest={1} value={payload?.[0].payload['reference']?.[0]} /> ({Math.round(bounds[0].lower.percentile * 100)}%) <br /></>}
                                        </>)
                                        : <></>}
                                </>}
                        </Typography>
                    </Paper>
                </div>
            );
        }

        return null;
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    // TODO
    // const renderLegend = (props: any) => {
    //     const { payload } = props as any;
    //     return (
    //         <ul>
    //             {
    //                 payload.map((entry: any, index: any) => (
    //                     <Fragment key={entry.value}><span style={{ borderWidth: 1, fontSize: 20, color: entry.color !== undefined ? entry.color.replace("url(#color", "#").replace(")","") : areaColors[index] }}>&#9679;</span> {entry.value}<br /></Fragment>
    //                 ))
    //             }
    //         </ul>
    //     );
    // }

    return (
        <div ref={canvasRef} style={{ height: height === undefined ? '10rem' : height }}>
            {showToolbar ?
                <Paper style={{ boxShadow: "none", display: 'inline-block', marginTop: theme.spacing(1), marginLeft: theme.spacing(1), padding: '2px' }} >
                    {!isFullScreen ?
                        <IconButton onClick={() => setPercentileChartDialogOpen(true)} size='small' style={{ marginRight: 2 }}>
                            <OpenInFull fontSize='small' />
                        </IconButton> : <></>}
                    {isFullScreen ?
                        <IconButton style={{ marginLeft: 2 }} onClick={() => {
                            html2canvas(canvasRef.current, { backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eee' }).then(canvas => {
                                try {
                                    canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]));
                                    setSnackbarOpen(true);
                                } catch (error) {
                                    console.log(error);
                                }
                            });
                        }}>
                            <Screenshot fontSize='small' />
                        </IconButton>
                        : <></>}
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <Alert severity="success">Image saved to clipboard!</Alert>
                    </Snackbar>
                </Paper> : <></>}
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart syncId={1} data={chartData} margin={{
                    top: 20, right: 20, left: 23, bottom: 2,
                }}>
                    <defs>
                        <pattern id="pattern-stripe-fan"
                            width="8" height="8"
                            patternUnits="userSpaceOnUse"
                            patternTransform="rotate(45)">
                            <rect width="4" height="8" transform="translate(0,0)" fill={colors.fanChart.referenceLine}></rect>
                        </pattern>
                        <linearGradient id="fanChartWide" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colors.fanChart.wide} stopOpacity={0.1} />
                            <stop offset="95%" stopColor={colors.fanChart.wide} stopOpacity={0.1} />
                        </linearGradient>
                        <linearGradient id="fanChartMedium" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colors.fanChart.medium} stopOpacity={0.2} />
                            <stop offset="95%" stopColor={colors.fanChart.medium} stopOpacity={0.2} />
                        </linearGradient>
                        <linearGradient id="fanChartNarrow" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colors.fanChart.narrow} stopOpacity={0.3} />
                            <stop offset="95%" stopColor={colors.fanChart.narrow} stopOpacity={0.3} />
                        </linearGradient>
                    </defs>
                    {/* <XAxis fontFamily={'Roboto, sans-serif'} type={'number'} tickCount={3}  tick={{ fontSize: 10 }} interval={'preserveStartEnd'} dataKey="name" /> */}
                    <CartesianGrid strokeOpacity={0.1} vertical={false} />
                    {historicalTimeSeries !== undefined
                        ? <XAxis
                            // scale='linear' 
                            scale="time"
                            type="number"
                            fontFamily={'Roboto, sans-serif'}
                            tick={{ fontSize: 10 }}
                            minTickGap={40}
                            tickLine={false}
                            domain={[chartData[0].pointInTime, chartData[chartData.length - 1].pointInTime]}
                            tickFormatter={date => {
                                // return moment(date).unix();
                                return moment(date).format('MMM YY');
                            }}
                            dataKey='pointInTime' />
                        : showXAxis ?
                            <XAxis type="number" tick={{ fontSize: fontSize, fill: colors.chart.axisLines }} allowDecimals={false} tickCount={40} interval={'preserveStartEnd'} domain={[startYear, startYear + horizon]} dataKey="year" />
                            : <XAxis
                                scale='auto'
                                fontFamily={'Roboto, sans-serif'}
                                tick={{ fontSize: 10 }}
                                ticks={[-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
                                interval={'preserveStartEnd'}
                                dataKey={"xxx"} />}
                    {/* <YAxis width={yAxisWidth} type="number" domain={[0, maxValue]} tick={<CustomizedAxisTick />}/> */}
                    <YAxis width={yAxisWidth} type="number" tick={<CustomizedAxisTick />} />
                    {referencePortfolioDevelopmentTimeSeries !== undefined ? <Area animationDuration={500} type="monotone" strokeDasharray="12 4" dataKey="reference" strokeWidth={2} strokeOpacity={0.2} stroke={colors.fanChart.referenceLine} fillOpacity={filled ? 0.05 : 0} fill={"url(#pattern-stripe-fan)"} /> : <></>}
                    {historicalReferenceTimeSeries !== undefined ? <Area animationDuration={500} type="monotone" dataKey="historicalReference" strokeWidth={2} strokeOpacity={0.2} stroke={colors.fanChart.referenceLine} fillOpacity={1} /> : <></>}
                    {historicalTimeSeries !== undefined ? <Area animationDuration={500} type="monotone" dataKey="historical" strokeWidth={3} stroke={colors.fanChart.medium} fillOpacity={1} fill={"url(#fanChartNarrow)"} /> : <></>}
                    {bounds.map((b: any, i: any) => {
                        return (<Area animationDuration={500} type="monotone" key={i + ''} dataKey={i + ''} strokeOpacity={(i / numberOfPercentiles * 0.4) + 0.5} strokeWidth={2} stroke={colors.fanChart.medium} fillOpacity={filled ? (i / numberOfPercentiles * 0.8) + 0.2 : 0} fill={colors.fanChart.narrow} />);
                    })}
                    {hideHorizon === true ? <></> : (<ReferenceLine stroke={colors.fanChart.referenceLine} strokeDasharray="3 3" strokeWidth={0.5} x={horizon} />)}
                    {targetAmount ? <ReferenceLine stroke={colors.fanChart.referenceLine} strokeDasharray="3 3" strokeWidth={0.5} y={targetAmount} /> : <></>}
                    <ReferenceLine stroke={colors.fanChart.referenceLine} strokeWidth={0.5} y={0} />
                    {/* {showLedgend ? <Legend layout='vertical' iconType='square' content={renderLegend} align='center' verticalAlign='bottom'></Legend> : <></>} */}
                    <Tooltip content={<CustomTooltip />} />
                </AreaChart>
            </ResponsiveContainer>
        </div>);
}

function timeStepToHorizon(payload: any, observationFrequency: Frequency) {
    const numYears = observationFrequency === Frequency.Annual ? payload?.[0].payload.name : Math.floor(payload?.[0].payload.name / 12);
    const numMonths = observationFrequency === Frequency.Annual ? 0 : payload?.[0].payload.name - numYears * 12;
    return 'Horizon: ' + numYears + ' years' + (numMonths > 0 ? ' ' + numMonths + ' months' : '');
}

export function areEqual(prevProps: any, nextProps: any) {
    var prevProps2 = Object.assign({}, prevProps);
    delete prevProps2.targetLabel;

    var nextProps2 = Object.assign({}, nextProps);
    delete nextProps2.targetLabel;

    if (JSON.stringify(prevProps2) === JSON.stringify(nextProps2)) {

        return true;
    }
    return false;
}

export default React.memo(PercentileFanChart, areEqual);
